<template>
  <div v-if="campaign" :key="key">
    <div class="row">
      <div class="col-4">
        <stats-card
          icon="ni ni-ungroup"
          type="primary"
          :title="$t('views.app.campaigns.overview.content_count_card')"
          :sub-title="String(campaign.meta.content_count)"
        >
        </stats-card>
      </div>
      <div class="col-4">
        <stats-card
          icon="ni ni-single-02"
          type="red"
          :title="$t('views.app.campaigns.overview.creator_count_card')"
          :sub-title="String(campaign.meta.total_creators)"
        >
        </stats-card>
      </div>
      <div class="col-4">
        <stats-card type="gray-dark">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">
                {{ $t('views.app.campaigns.overview.comment_count_card') }}
              </h5>
              <span class="h2 font-weight-bold mb-0">
                {{ campaign.meta.comment_count }}
                <span class="text-success text-xs font-weight-normal ml-2 mr-1">
                  <i class="fa fa-check"></i> {{ classifiedCommentsPct }} %
                </span>
                <span class="text-xs font-weight-normal text-muted">
                  {{
                    $t(
                      'views.app.campaigns.overview.classified_comment_count_card'
                    )
                  }}
                </span>
              </span>
            </div>
          </div>
        </stats-card>
      </div>
    </div>

    <div class="row">
      <div class="col-8">
        <app-carousel type="highlighted-comments"></app-carousel>
      </div>
      <div class="col-4">
        <app-list type="kpis" />
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <app-dash-charts type="content-distribution"></app-dash-charts>
      </div>
      <div class="col-4">
        <app-dash-charts type="sentiment-distribution"></app-dash-charts>
      </div>
      <div class="col-4">
        <app-dash-charts
          type="provider-sentiment-distribution"
        ></app-dash-charts>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <app-dash-charts type="comments-distribution" />
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <app-list type="contents-unclassified" />
      </div>
      <div class="col-4">
        <app-list type="tags" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { FETCH_CAMPAIGN_EVENT } from '@/helpers/events'

export default {
  name: 'dashboard',

  data() {
    return {
      key: 1,
    }
  },

  computed: {
    ...mapGetters({
      campaign: 'campaigns/getCampaign',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    classifiedCommentsPct() {
      const classified = this.campaign.meta.classified_comment_count
      const comments = this.campaign.meta.comment_count
      const result = ((classified * 100) / comments).toFixed(2)

      return Number(result) ? result : 0
    },
  },

  mounted() {
    this.$eventBus.$on(FETCH_CAMPAIGN_EVENT, () => {
      this.key++
    })
  },
}
</script>
